$(document).ready(function() {
    var controller = new ScrollMagic.Controller();
    var sceneBG1 = new ScrollMagic.Scene({
            triggerElement: "#vorteile",
            duration: "80%",
            offset: 10,
            reverse: true
        })
        .setClassToggle(".bg-shape", "sm-fade-in")
        .setPin(".bg-shape")
        .addTo(controller);

    var sceneBG3 = new ScrollMagic.Scene({
            triggerElement: "#aufbau-ablauf",
            duration: "80%",
            offset: 50,
            reverse: true
        })
        .setClassToggle(".bg-shape-2", "sm-fade-in")
        .setPin(".bg-shape-2")
        .addTo(controller);


    // var scene3 = new ScrollMagic.Scene({
    //         triggerElement: ".sticky-container-1",
    //         duration: $(".sticky-content").height() - 400,
    //         triggerHook: 0.3,
    //         reverse: true
    //     })
    //     .setPin(".sticky-image")
    //     .addTo(controller);


    /*-----------------------------------------------------------------------------------
        Hide bubbles to avoid overlay at footer
    -----------------------------------------------------------------------------------*/
    var sceneBG4 = new ScrollMagic.Scene({
            triggerElement: "#angebot",
            triggerHook: 0,
            reverse: true
        })
        .setClassToggle(".bg-shape", "sm-hide")
        .addTo(controller);

    var sceneBG5 = new ScrollMagic.Scene({
            triggerElement: "#angebot",
            triggerHook: 0,
            reverse: true
        })
        .setClassToggle(".bg-shape-2", "sm-hide")
        .addTo(controller);


    $('.section, .slideUpAnimated').each(function() {

        var controller2 = new ScrollMagic.Controller();

        var sceneSections = new ScrollMagic.Scene({
                triggerElement: this.children[0],
                triggerHook: 0.8, // show, when scrolled 10% into view
                //offset: 50 // move trigger to center of element
                // trigger: 1
            })
            .setClassToggle(this, "sm-show")
            .addTo(controller2);
    });
});